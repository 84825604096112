import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Homepage from './Views/Homepage';
import NotFound from './Views/NotFound';

function App() {
  return (
    <div className="menu">
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/menu" element={<Homepage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
