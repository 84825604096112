import React, { useEffect, useState } from 'react'
import "../Styles/Homepage.scss";
import LogoImg from "../Assets/LOGO.png"
import GoldenRing from "../Assets/golden-ring.png"
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { BsChevronLeft, BsChevronRight } from "react-icons/bs"

import sushi3 from "../Assets/sushi/Asset 3.webp"
import sushi4 from "../Assets/sushi/Asset 4.webp"
import sushi5 from "../Assets/sushi/Asset 5.webp"
import sushi6 from "../Assets/sushi/Asset 6.webp"

import drink3 from "../Assets/drinks/Asset 3.webp"
import drink4 from "../Assets/drinks/Asset 4.webp"
import drink5 from "../Assets/drinks/Asset 5.webp"
import drink6 from "../Assets/drinks/Asset 6.webp"
import drink7 from "../Assets/drinks/Asset 7.webp"
import drink8 from "../Assets/drinks/Asset 8.webp"
import drink9 from "../Assets/drinks/Asset 9.webp"
import drink10 from "../Assets/drinks/Asset 10.webp"
import drink11 from "../Assets/drinks/Asset 11.webp"

import menu2 from "../Assets/menu/pag2.webp"
import menu3 from "../Assets/menu/pag3.webp"
import menu4 from "../Assets/menu/pag4.webp"
import menu5 from "../Assets/menu/pag5.webp"
import menu6 from "../Assets/menu/pag6.webp"
import menu7 from "../Assets/menu/pag7.webp"
import menu8 from "../Assets/menu/pag8.webp"
import menu9 from "../Assets/menu/pag9.webp"
import menu10 from "../Assets/menu/pag 10.webp"
import { useSwipeable } from 'react-swipeable';

const Homepage = () => {
    const [seeMenu, setSeeMenu] = useState(false)
    const [currentIndex, setCrrentIndex] = useState(0)
    const [activeMenu, setActiveMenu] = useState([])
    const imageListSushi = [sushi3, sushi4, sushi5, sushi6]
    const imageListDrinks = [drink3, drink4, drink5, drink6, drink7, drink8, drink9, drink10, drink11]
    const imageListMenu = [menu2, menu3, menu4, menu5, menu6, menu7, menu8, menu9, menu10]

    const handlers = useSwipeable({
        onSwiped: (eventData) => {
            console.log(eventData)
            switch (eventData?.dir) {
                case 'Left': setCrrentIndex(prev => { return prev < activeMenu.length - 1 ? prev + 1 : prev }); break;
                case 'Right': setCrrentIndex(prev => { return prev > 0 ? prev - 1 : 0 }); break;

                default: return;
            }
        }
    });

    useEffect(() => {

        imageListSushi.forEach((image) => {
            new Image().src = image
        });
        imageListDrinks.forEach((image) => {
            new Image().src = image
        });
        imageListMenu.forEach((image) => {
            new Image().src = image
        });
    }, [])

    const handleSeeMenu = (menu) => {
        setSeeMenu(true)
        switch (menu) {
            case 'drinks': setActiveMenu(imageListDrinks); break;
            case 'sushi': setActiveMenu(imageListSushi); break;
            case 'menu': setActiveMenu(imageListMenu); break;
            default: setActiveMenu(imageListDrinks)
        }
    }
    return (
        <div className='homepage position-relative w-100 d-flex align-items-center justify-content-center overflow-hidden'>
            <div className='middle-container h-100 d-flex align-items-center justify-content-start py-5 flex-column'>
                <img src={LogoImg} alt="OPPO LOGO" className='img-fluid' style={{ marginTop: "8rem" }} />
            </div>

            <div className='buttons-container w-100 d-flex align-items-center justify-content-around gap-1'>
                <button onClick={() => handleSeeMenu("menu")} className='button-indiv d-flex align-items-center justify-content-center position-relative'>
                    <span>FOOD</span>
                    <img src={GoldenRing} alt="" className='golden-ring' />
                </button>
                <button onClick={() => handleSeeMenu("sushi")} className='button-indiv d-flex align-items-center justify-content-center position-relative'>
                    <span>SUSHI</span>
                    <img src={GoldenRing} alt="" className='golden-ring' />
                </button>
                <button onClick={() => handleSeeMenu("drinks")} className='button-indiv d-flex align-items-center justify-content-center position-relative'>
                    <span>DRINKS</span>
                    <img src={GoldenRing} alt="" className='golden-ring' />
                </button>
            </div>

            {
                seeMenu ? <div className="overlay"></div> : null
            }
            {
                seeMenu ?
                    <>
                        <div {...handlers} onClick={(e) => e.stopPropagation()} className='pdf-container flex-column justify-content-between'>
                            <img src={activeMenu[currentIndex]} alt="drinks menu" className='img-fluid mx-auto' />
                            <div className='d-flex w-100 pt-5 align-items-center justify-content-center gap-3'>

                                <button className={`control-btn ${currentIndex === 0 ? "disabled-btn" : ""}`} onClick={() => setCrrentIndex(prev => { return prev > 0 ? prev - 1 : 0 })}><BsChevronLeft /></button>
                                <button className={`control-btn ${currentIndex === activeMenu.length - 1 ? "disabled-btn" : ""}`} onClick={() => setCrrentIndex(prev => { return prev < activeMenu.length - 1 ? prev + 1 : prev })}><BsChevronRight /></button>
                            </div>
                        </div>
                        <button className='close-btn' onClick={() => { setSeeMenu(false); setCrrentIndex(0) }}>CLOSE</button>
                    </>
                    : null
            }
        </div>

    )
}

export default Homepage